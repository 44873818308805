<script lang="ts">
  import TextButton from '@svelte/components/atoms/button/text/TextButton.svelte';
  import FullWidthImage from '@svelte/components/atoms/image/full-width/FullWidth.svelte';
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import type { CdnImageDataFragment } from 'gatsby/graphqlTypes';

  export let bgImageCdn: CdnImageDataFragment;
  export let heading: string;
  export let subheading: string;
  export let CTA: string | undefined = undefined;
</script>

<div
  class="relative flex h-body-minus-top-header w-full flex-col items-center justify-center overflow-hidden md:flex-row"
>
  <!-- Background -->
  <div class="absolute inset-0 h-full w-full">
    <FullWidthImage
      data={bgImageCdn}
      blur={[100]}
      colour={['F9D4A9', 60]}
      height="h-screen"
      eager
    />
  </div>

  <Container
    className="relative z-10 pb-rhythm5 text-primary-700 md:pt-rhythm5 text-center pt-rhythm5"
  >
    <Heading
      level={1}
      bold
      color=""
      size={TextSize.DoublePica}
      tabletSize={TextSize.DoublePica}
      withoutSpacing
      className="mb-rhythm0">{heading}</Heading
    >

    <InterfaceText color="" className="block" bold size={TextSize.Pica}
      >{subheading}</InterfaceText
    >

    {#if CTA}
      <TextButton text={CTA} className="mt-rhythm6" />
    {/if}
  </Container>
</div>
