<script lang="ts">
  import Heading, {
    type DisplayTextSize
  } from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import FaqItem from './FAQItem.svelte';
  import type { FAQContent } from './types';

  export let content: FAQContent;
  export let headingSize: DisplayTextSize | undefined = undefined;
  export let containerClassName: string | undefined = undefined;
  export let className: string | undefined = undefined;
</script>

<div class={containerClassName}>
  <Heading level={2} size={headingSize}>{content.title}</Heading>

  <ul role="menu" class={className}>
    {#each content.questions as question}
      <FaqItem
        question={question.question}
        answer={question.answer}
        image={question.image}
      />
    {/each}
  </ul>
</div>
