<script lang="ts">
  import Disclosure from '@svelte/components/atoms/disclosure/Disclosure.svelte';
  import ResponsiveImage from '@svelte/components/atoms/image/responsive/ResponsiveImage.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import { CdnImageDataFragment } from 'gatsby/graphqlTypes';

  export let question: string;
  export let answer: string;
  export let image: CdnImageDataFragment | undefined = undefined;
</script>

<li class="border-b border-primary py-rhythm1 last:border-0">
  <Disclosure
    name={question}
    level={0}
    className="md:grid md:grid-cols-2 md:gap-6"
  >
    {#if image}
      <div class="my-rhythm0 h-rhythm8 overflow-hidden rounded-md">
        <ResponsiveImage className="w-full h-full" crop="none" data={image} />
      </div>
    {/if}
    <Paragraph className="mt-rhythm1">{answer}</Paragraph>
  </Disclosure>
</li>
