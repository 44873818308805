import { TextSize } from "@svelte/components/atoms/typography/props";
import type { FAQContent } from "@svelte/components/organisms/FAQ/types";
import FAQ from "@svelte/reactify/FAQ";
import {
	MultifunctionalBarsLandingTemplate,
	MultifunctionalBarsLandingTemplate_2,
} from "@svelte/reactify/landing/MultifunctionalBarsLanding";
import ResponsiveImage from "@svelte/reactify/ResponsiveImage";
import VideoCarousel from "@svelte/reactify/VideoCarousel";

import { Container } from "components/atoms/layout/Container";
import PageContentLayout from "components/layouts/PageContentLayout";
import SlateContent from "components/molecules/cms/SlateContent";
import { graphql } from "gatsby";
import type {
	CdnImageDataFragment,
	MultifunctionalBarsLandingPageQuery,
	ResponsiveVideoFragment,
} from "gatsby/graphqlTypes";
import type { AppPageProps } from "gatsby/types";
import CompanyReviews from "lib/reviews/CompanyReviewsIo";
// biome-ignore lint/style/useImportType: <explanation>
import React from "react";

export { Head } from "components/atoms/Head";
export type Props = AppPageProps<MultifunctionalBarsLandingPageQuery>;

export const query = graphql`
  query MultifunctionalBarsLandingPage {
  c: strapiMultifunctionalBarsLandingPage {
    hero {
      message
      title
      image {
        ...CdnImageData
      }
    }
    postHeroTag
    firstPitchSlate {
      ...SlateContent
    }
    firstPitchImage {
      ...CdnImageData
    }
    howToUseFaq {
      title
      questions {
        question
        answer
        image {
          ...CdnImageData
        }
      }
    }
    faq {
      title
      questions {
        question
        answer
      }
    }
    inActionTitle
    inActionText
    inActionVideos {
      ...ResponsiveVideo
    }
    comparison {
      title
      headings {
        text
        image {
          ...CdnImageData
        }
      }
      rows {
        title
        image {
          ...CdnImageData
        }
        cells {
          text
          trafficLightBackground
          image {
            ...CdnImageData
          }
        }
      }
    }
    ingredientsTitle
    ingredientsParagraph
    ingredientsSpotlight {
      image {
        ...CdnImageData
      }
      title
      text
    }
    asSeenInTitle
    asSeenInItems {
      image {
        url
        ...CdnImageData
      }
      title
      text
    }
    qualityHeading
    qualityParagraph
    qualityMarks {
      title
      text
      image {
        alternativeText
        url
        width
        height
      }
    }
  }
}
`;

const MultifunctionalBarsLandingPage: React.FC<Props> = (props) => {
	const data = props.data.c;
	// const image = data?.image;
	const pageContext = props.pageContext;
	if (!data) {
		throw Error("no data");
	}

	const howtoUseFAQContent = data.howToUseFaq as FAQContent;

	return (
		<PageContentLayout footerNavigation={pageContext.footerNavigation}>
			<MultifunctionalBarsLandingTemplate data={props.data} />

			<Container className="py-rhythm0 2xl:grid 2xl:grid-cols-2 2xl:gap-10">
				<ResponsiveImage
					height="h-rhythm10 2xl:h-auto"
					className="rounded-md"
					data={data.firstPitchImage as CdnImageDataFragment}
				/>
				<SlateContent content={data.firstPitchSlate} />
			</Container>

			<VideoCarousel
				title={data.inActionTitle}
				paragraph={data.inActionText}
				videos={data.inActionVideos as ResponsiveVideoFragment[]}
			/>

			<Container>
				<FAQ
					containerClassName="flex-cnt flex-col"
					className="py-rhythm0 lg:max-w-3/4"
					content={howtoUseFAQContent}
					headingSize={TextSize.GreatPrimer}
				/>
			</Container>

			<div className="bg-primary-200 py-rhythm0 md:py-rhythm2">
				<CompanyReviews />
			</div>
			<MultifunctionalBarsLandingTemplate_2 data={props.data} />
		</PageContentLayout>
	);
};

export default MultifunctionalBarsLandingPage;
