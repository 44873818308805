<script lang="ts">
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import type { IngredientSpotLightItem } from './types';
  import IngredientSpotlightItem from './IngredientSpotlightItem.svelte';

  export let title: string;
  export let paragraph: string;
  export let ingredients: IngredientSpotLightItem[];
</script>

<section class="min-w-3/4 lg:text-center">
  <Heading level={2}>{title}</Heading>
  <Paragraph>{paragraph}</Paragraph>

  <div
    class="grid grid-cols-1 grid-rows-[300px_200px_200px_300px] gap-4 text-left lg:grid-rows-[400px_300px_300px_400px]"
  >
    <!-- First row -->
    <IngredientSpotlightItem ingredient={ingredients[0]} />

    <!-- Second row with two columns -->
    <div class="grid grid-cols-2 gap-4">
      <IngredientSpotlightItem ingredient={ingredients[1]} />
      <IngredientSpotlightItem ingredient={ingredients[2]} />
    </div>

    <!-- Third row -->
    <IngredientSpotlightItem ingredient={ingredients[3]} />

    <!-- Fourth row -->
    <IngredientSpotlightItem ingredient={ingredients[4]} />
  </div>
</section>
