<script lang="ts">
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import FAQ from '@svelte/components/organisms/FAQ/FAQ.svelte';
  import type { FAQContent } from '@svelte/components/organisms/FAQ/types';
  import IngredientSpotlight from '@svelte/components/organisms/ingredients/spotlight/IngredientSpotlight.svelte';
  import { IngredientSpotLightItem } from '@svelte/components/organisms/ingredients/spotlight/types';
  import { QualityMarkItem } from '@svelte/components/organisms/quality-marks/types';
  import QualityMarks from '@svelte/components/organisms/quality-marks/QualityMarks.svelte';
  import type {
    MultifunctionalBarsLandingPageQuery,
    StrapiMultifunctionalBarsLandingPage
  } from 'gatsby/graphqlTypes';
  import CompetitionComparison from './components/CompetitionComparison.svelte';
  import { Cell, Row, type AsSeenOnItem } from './components/types';
  import AsSeenOn from './components/AsSeenOn.svelte';

  export let data: MultifunctionalBarsLandingPageQuery;

  const {
    comparison,
    faq,
    ingredientsParagraph,
    ingredientsTitle,
    ingredientsSpotlight,
    qualityHeading,
    qualityMarks,
    qualityParagraph,
    asSeenInTitle,
    asSeenInItems
  } = data.c as unknown as StrapiMultifunctionalBarsLandingPage;
  // const howtoUseFAQContent = howToUseFaq as FAQContent;
  const faqContent = faq as FAQContent;
  const ingredients =
    ingredientsSpotlight as unknown as IngredientSpotLightItem[];
  const qMarks = qualityMarks as unknown as QualityMarkItem[];
  const comparisonTitle = comparison?.title as string;
  const comparisonHeadings = (comparison?.headings || []) as Cell[];
  const comparisonRows = (comparison?.rows || []) as Row[];
  const asSeenOnTitle = asSeenInTitle as string;

  const asSeenOnItems: AsSeenOnItem[] = (asSeenInItems || []).map(item => {
    return {
      title: item?.title as string,
      url: item?.text as string,
      image: item?.image,
      imageUrl: item?.image?.url as string
    } as AsSeenOnItem;
  });
</script>

<Container>
  <div class="lg:flex-cnt">
    <CompetitionComparison
      title={comparisonTitle}
      headings={comparisonHeadings}
      rows={comparisonRows}
    />
  </div>

  <div class="lg:flex-cnt">
    <IngredientSpotlight
      title={ingredientsTitle}
      paragraph={ingredientsParagraph}
      {ingredients}
    />
  </div>

  <AsSeenOn title={asSeenOnTitle} items={asSeenOnItems} />

  <QualityMarks
    heading={qualityHeading}
    paragraph={qualityParagraph}
    items={qMarks}
  />

  <!-- <FAQ
    className="py-rhythm0"
    content={howtoUseFAQContent}
    headingSize={TextSize.GreatPrimer}
  /> -->
  <FAQ
    containerClassName="flex-cnt flex-col"
    className="py-rhythm0 lg:max-w-3/4"
    content={faqContent}
    headingSize={TextSize.GreatPrimer}
  />
</Container>
