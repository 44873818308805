<script lang="ts">
  import HeroBanner from '@svelte/components/molecules/banners/HeroBanner.svelte';
  import TagBox from '@svelte/components/molecules/banners/TagBox.svelte';
  import type {
    MultifunctionalBarsLandingPageQuery,
    StrapiMultifunctionalBarsLandingPage
  } from 'gatsby/graphqlTypes';
  import type { CdnImageDataFragment } from 'gatsby/graphqlTypes';

  export let data: MultifunctionalBarsLandingPageQuery;

  const { hero, postHeroTag } =
    data.c as unknown as StrapiMultifunctionalBarsLandingPage;
  const heroBannerImage = hero.image as CdnImageDataFragment;
</script>

<div class="relative">
  <div class="absolute inset-0 z-10 mx-auto">
    <div class="my-rhythm1">
      <div class="flex-cnt -ml-0.5 flex-col -space-x-0.5">
        <img
          src="/icons/reviewsio-logo.svg"
          alt="Reviews IO logo"
          class="block w-24"
          loading="lazy"
          decoding="async"
        />
        <div class="flex-cnt mt-rhythm-4 space-x-2">
          {#each Array(5) as _}
            <svg
              focusable="false"
              preserveAspectRatio="xMidYMid meet"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              width="28"
              height="28"
              viewBox="0 0 32 32"
              aria-hidden="true"
              class="fill-secondary"
              ><path
                d="M16,2l-4.55,9.22L1.28,12.69l7.36,7.18L6.9,30,16,25.22,25.1,30,23.36,19.87l7.36-7.17L20.55,11.22Z"
              ></path></svg
            >
          {/each}
        </div>
      </div>
    </div>
  </div>

  <HeroBanner
    bgImageCdn={heroBannerImage}
    heading={hero.title}
    subheading={hero.message}
  />
</div>

<TagBox text={postHeroTag} />
