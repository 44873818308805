<script lang="ts">
  import { makeClassNames } from 'lib/util';
  import {
    ButtonRole,
    ButtonSize,
    borderForKind,
    makeRoleClassNames,
    makeSizeConfig
  } from '../common';
  import type { HTMLButtonAttributes } from 'svelte/elements';
  import InterfaceText from '../../typography/InterfaceText/InterfaceText.svelte';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface $$Props extends HTMLButtonAttributes {
    className?: string;
    kind?: ButtonRole;
    size?: ButtonSize;
    withoutFocusClassNames?: boolean;
    text: string;
  }

  export let className: string | null = null;
  export let kind: ButtonRole = 'primary';
  export let size: ButtonSize = 'm';
  export let withoutFocusClassNames = false;
  export let text: string;
  export let type: HTMLButtonAttributes['type'] = 'button';

  const roleConfig = makeRoleClassNames({
    role: kind,
    withFocus: !withoutFocusClassNames
  });
  const sizeConfig = makeSizeConfig(size);
  const borderClassNames = borderForKind(kind, size);
  const mergedClassName = makeClassNames(
    'button',
    className,
    borderClassNames,
    roleConfig,
    sizeConfig?.classNames
  );
</script>

<InterfaceText
  as="button"
  color=""
  size={sizeConfig?.textSize}
  className={mergedClassName}
  on:click
  {type}
  {...$$restProps}
>
  {text}
</InterfaceText>
