<script lang="ts">
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import type { AsSeenOnItem } from './types';

  export let title: string;
  export let items: AsSeenOnItem[];
</script>

<section class="text-center">
  <Heading level={2}>{title}</Heading>

  <div class="lg: grid auto-rows-[100px] grid-cols-2 gap-4 lg:grid-cols-3">
    {#each items as item (item.title)}
      <div class="flex-cnt h-full p-4">
        {#if item.url && item.url.startsWith('https')}
          <a
            href={item.url}
            target="_blank"
            rel="noopener"
            class="h-full w-full"
          >
            <img
              class="h-full w-full object-contain"
              src={item.imageUrl}
              alt={item.image.alt}
            />
          </a>
        {:else}
          <img
            class="h-full w-full object-contain"
            src={item.imageUrl}
            alt={item.image.alt}
          />
        {/if}
      </div>
    {/each}
  </div>
</section>
