<script lang="ts">
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import type { QualityMarkItem } from './types';
  import QualityMark from './QualityMarkItem.svelte';
  import { makeClassNames } from 'lib/util';

  export let heading: string;
  export let paragraph: string;
  export let items: QualityMarkItem[];
  export let className: string | undefined = undefined;
</script>

<section
  class={makeClassNames(className, 'lg:flex lg:flex-col lg:items-center')}
>
  <Heading level={2}>{heading}</Heading>
  <Paragraph>{paragraph}</Paragraph>
  <div class="my-rhythm2 lg:my-rhythm4 lg:grid lg:grid-cols-3 lg:gap-8">
    {#each items as item}
      <QualityMark {item} />
    {/each}
  </div>
</section>
