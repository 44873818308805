<script lang="ts">
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import type { QualityMarkItem } from './types';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';

  export let item: QualityMarkItem;
</script>

<div class="mb-rhythm1 border-l-2 border-primary pl-rhythm0">
  <Heading level={3} withoutSpacing bold>{item.title}</Heading>
  <Paragraph>{item.text}</Paragraph>

  {#if item.image}
    <div class="py-rhythm0">
      <img
        loading="lazy"
        class="h-rhythm3 w-auto"
        src={item.image.url}
        height={item.image.height}
        width={item.image.width}
        alt={item.image.alternativeText}
      />
    </div>
  {/if}
</div>
