<script lang="ts">
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import MovingImage from '@svelte/components/atoms/moving-image/MovingImage.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import type { ResponsiveVideoFragment } from 'gatsby/graphqlTypes';
  import { makeClassNames } from 'lib/util';

  export let title: string;
  export let paragraph: string;
  export let videos: ResponsiveVideoFragment[] = [];

  const videoElementsInView: boolean[] = [];
  const videoElements: HTMLVideoElement[] = [];

  const focusOnVideo = (index: number) => () =>
    videoElements[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
</script>

<div class="bg-primary pb-rhythm1">
  <Container className="lg:flex-cnt lg:flex-col text-pearl-300">
    <Heading level={2} color="" className="min-w-[260px]">{title}</Heading>
    <Paragraph color="">{paragraph}</Paragraph>
    <div
      class="relative flex h-rhythm12 w-full snap-x snap-mandatory gap-6 overflow-x-auto py-rhythm0 scrollbar-none lg:hidden"
    >
      {#each videos as video, index}
        <MovingImage
          className="snap-start w-4/5 md:w-rhythm10 h-full object-cover shrink-0 rounded-lg"
          inViewOpts={{
            root: null,
            threshold: 0.5
          }}
          bind:isInView={videoElementsInView[index]}
          bind:element={videoElements[index]}
          {video}
        />
      {/each}
    </div>

    <div class="flex-cnt mt-4 w-full lg:hidden">
      {#each videos as _, index}
        <span
          class={makeClassNames(
            'mx-1 cursor-pointer text-4xl',
            videoElementsInView[index] ? 'text-secondary' : 'text-pearl-500'
          )}
          on:click={focusOnVideo(index)}
          on:keydown={focusOnVideo(index)}>•</span
        >
      {/each}
    </div>

    <div
      class="relative hidden h-rhythm12 gap-6 overflow-x-auto py-rhythm0 lg:flex"
    >
      {#each videos as video}
        <MovingImage
          controls
          autoplay={false}
          className="snap-start w-4/5 md:w-rhythm10 h-full object-cover shrink-0 rounded-lg"
          {video}
        />
      {/each}
    </div>
  </Container>
</div>
