<script lang="ts">
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import { Cell, Row } from './types';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import StaticImage from '@svelte/components/atoms/image/static/StaticImage.svelte';
  import { TextSize } from 'components/atoms/typography';
  import { makeClassNames } from 'lib/util';

  export let title: string;
  export let headings: Cell[];
  export let rows: Row[];
</script>

<div class="lg:max-w-3xl">
  <Heading level={2}>{title}</Heading>

  <table class="my-rhythm0 w-full table-fixed">
    <colgroup>
      <col span="1" style="width: 25%;" />
      <col span="1" style="width: 25%;" />
      <col span="1" style="width: 25%;" />
      <col span="1" style="width: 25%;" />
    </colgroup>

    <thead>
      <tr>
        <th />
        {#each headings as heading, i}
          <th class="py-3">
            <div
              class={makeClassNames(
                'rounded-md border-2 border-primary lg:mx-5',
                i === 0 ? 'bg-emerald-300' : 'bg-pearl-600'
              )}
            >
              <div
                class="flex-cnt -mt-6 mb-rhythm-2 w-full xl:-mt-10 xl:mb-rhythm0"
              >
                <div class="h-rhythm4 w-rhythm4 xl:h-rhythm6 xl:w-rhythm6">
                  {#if heading.image}
                    <StaticImage
                      width={200}
                      height={200}
                      data={heading.image}
                      crop="none"
                      objectFit="object-contain"
                      className="w-full h-full"
                    />
                  {/if}
                </div>
              </div>

              <InterfaceText
                desktopSize={TextSize.BodyCopy}
                size={TextSize.LongPrimer}
                className="uppercase leading-4 block pb-rhythm0"
                color="text-primary">{heading.text}</InterfaceText
              >
            </div>
          </th>
        {/each}
      </tr>
    </thead>
    <tbody class="text-gray-600 text-sm font-light">
      {#each rows as row}
        <tr class="border-b-2 border-primary first:border-t-2">
          <InterfaceText
            bold
            as="td"
            className="py-3"
            size={TextSize.LongPrimer}>{row.title}</InterfaceText
          >
          {#each row.cells || [] as cell, i}
            <td>
              <div class="flex-cnt flex">
                <InterfaceText
                  className={makeClassNames(
                    'lg:mx-8 py-1 text-center w-full leading-4 block',
                    'border-4 rounded-md',
                    i === 0 || cell.trafficLightBackground === 'green'
                      ? 'bg-emerald-300 border-emerald-800'
                      : cell.trafficLightBackground === 'amber'
                        ? 'bg-orange-300 border-orange-800'
                        : 'border-red-900 bg-red-600'
                  )}
                  color={cell.trafficLightBackground === 'red'
                    ? 'text-pearl-100'
                    : ''}
                  size={TextSize.LongPrimer}>{cell.text}</InterfaceText
                >
              </div>
            </td>
          {/each}
        </tr>
      {/each}
    </tbody>
  </table>
</div>
