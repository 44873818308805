<script lang="ts">
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import type { IngredientSpotLightItem } from './types';
  import ResponsiveImage from '@svelte/components/atoms/image/responsive/ResponsiveImage.svelte';

  export let ingredient: IngredientSpotLightItem;
  export let image = ingredient.image;
</script>

<div class="relative flex flex-col justify-end overflow-hidden bg-primary-200">
  <div class="relative z-10 text-pearl-100">
    <div
      class="absolute z-10 h-full w-full bg-pearl bg-opacity-10 bg-clip-padding backdrop-blur backdrop-contrast-100 backdrop-saturate-100 backdrop-filter"
    />
    <div class="p-3">
      <InterfaceText
        color=""
        bold
        size={TextSize.BodyCopy}
        className="relative z-10 block">{ingredient.title}</InterfaceText
      >
      <InterfaceText
        color=""
        size={TextSize.Brevier}
        className="relative z-10 block">{ingredient.text}</InterfaceText
      >
    </div>
  </div>

  {#if image}
    <div class="absolute inset-0 h-full w-full">
      <ResponsiveImage data={image} />
    </div>
  {/if}
</div>
