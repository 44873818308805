<script lang="ts">
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { Marquee } from '@selemondev/svelte-marquee';

  export let text: string;
  const marqueeItems = text.split('. ');
</script>

<div class="w-screen bg-primary py-rhythm0">
  <Container className="text-center">
    <Marquee fade={true} direction="left">
      {#each marqueeItems as item}
        <InterfaceText color="text-secondary" bold
          >{item.toUpperCase()}</InterfaceText
        >
        <InterfaceText color="text-secondary" bold>•</InterfaceText>
      {/each}
    </Marquee>
  </Container>
</div>
